import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import AnimatedCursor from "react-animated-cursor";
import WhatsAppIcon from './Whatsapp';
import NavBar from "../components/Navbar/NavBar";
import { useDocTitle } from '../components/CustomHook';
import ServicesSM from '../components/Services';
import Testimonials from '../components/Testimonials';
import Cta from '../components/Cta';
import { ServicesDetailed } from '../components/ServicesDetailed';
import ServicesBanner from '../components/ServicesBanner';

const useIsSmallScreen = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isSmallScreen;
};

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};


export const ServicesPage = (props) => {
  const cursorColor = "0,94,231"; // RGB for blue
  const isSmallScreen = useIsSmallScreen();
  useDocTitle("SERVICES | ATHEM FINANCIAL SERVICES");
  return (
    <div className='mt-10'>
          {!isMobileDevice() && (
        <AnimatedCursor
          color={cursorColor}
          innerSize={10}
          outerSize={35}
          innerScale={1}
          outerScale={1.7}
          outerAlpha={0}
          outerStyle={{
            border: `1px solid rgba(${cursorColor}, 0.8)`,
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
            backgroundColor: `rgba(${cursorColor}, 0.2)`,
          }}
          innerStyle={{
            border: `1px solid rgba(${cursorColor}, 0.8)`,
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
            backgroundColor: `rgba(${cursorColor}, 0.2)`,
          }}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.link',
            'img'
          ]}
        />
      )}
    <WhatsAppIcon/>
    <NavBar/>
    <ServicesBanner/>
    < ServicesSM />  
    <ServicesDetailed/>  
    <Testimonials/>
    <Cta/>
    <Footer/>
    </div>  
  );
};
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import founder from "../images/founders5.svg"

export function Founders() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        {/* <svg
          aria-hidden="true"
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
        >
          <defs>
            <pattern
              x="50%"
              y={-1}
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
        </svg> */}
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-4 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="text-blue text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Co-Founders</h1>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden hidden lg:block">
          <img
            alt=""
            src={founder}
            className="w-[48rem] max-w-none bg-gray-900 sm:w-[57rem]"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p className='text-xl font-semibold'>
                Aakash H. Mehta
              </p>
              <p className='underline font-medium'>Chartered Accountant | Global Market Analyst</p>
              <br />
              <p>
                Aakash H. Mehta is a seasoned global market analyst with over 9 years of experience specializing in equities and indices on a Global Level. He excels in delivering unique, real-time global market analysis and advisory services across a wide range of financial instruments. Aakash’s expertise in <b>Neo Wave Theory, Elliott Wave Theory, and Gann Theory</b> gives him a competitive edge in the industry. Leveraging these advanced methodologies, Aakash provides live analysis for all indices including equities, commodities, currencies, derivatives, and cryptocurrencies on a global scale. This extensive knowledge base enables him to offer accurate insights and strategic advice, ensuring you make informed investment decisions in a constantly evolving market landscape.
              </p>

              <br />


              <p className='text-xl font-semibold'>
                Manan A. Mehta

              </p>
              <p className='underline font-medium'>Marketing | Strategic Expert</p>
              <br />
              <p>
                Manan A. Mehta brings over 7 years of hands-on experience in marketing and strategy, with a passion for solving complex problems. His personalized approach helps our valued clients navigate the competitive financial industry and achieve sustainable growth. Manan’s deep understanding of market trends and user behavior enables him to craft marketing strategies that not only enhance customer engagement but also foster strong partnerships. At ATHEM Financial Services, his innovative mindset and business expertise drive real success, making a tangible impact every day.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

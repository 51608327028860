import { useState } from 'react';
import founder from "../images/services1.svg";

export function ServicesDetailed() {
  const [isExpandedGlobal, setIsExpandedGlobal] = useState(false);
  const [isExpandedAdvisory, setIsExpandedAdvisory] = useState(false);
  const [isExpandedPortfolio, setIsExpandedPortfolio] = useState(false);
  const [isExpandedConsultation, setIsExpandedConsultation] = useState(false);

  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0 -mt-10 lg:mr-60">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        {/* Background SVG or other elements */}
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-4 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="text-blue mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">OUR SERVICES</h1>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden hidden lg:block">
          <img
            alt=""
            src={founder}
            className="w-[48rem] max-w-none bg-gray-900 sm:w-[57rem]"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">

              {/* Global Live Analysis Section */}
              <p className='text-l font-semibold'>
                1) Global Live Analysis for All Indices of the World
              </p>
              <p>
                At ATHEM Financial Services, we provide comprehensive, real-time market analysis across all major global stock indices. Whether you're interested in the S&P 500, FTSE, DAX, Nikkei, Sensex or Nifty, we deliver live insights that keep you ahead of market trends and help you make informed investment decisions.

                {isExpandedGlobal && (
                  <>
                    <br /> Real-Time Global Market Monitoring: Stay updated on all major indices with live data, advanced charting tools, and analysis of market movements across global exchanges.
                    <br /> Multi-Asset Class Integration: We analyze not only equities but also commodities, and currencies to provide a holistic view of market movements across different asset classes.
                  </>
                )}
                <br />
                <button onClick={() => setIsExpandedGlobal(!isExpandedGlobal)} className="text-blue font-semibold underline decoration-blue">
                  {isExpandedGlobal ? 'See Less...' : 'See More...'}
                </button>
              </p>

              {/* Advisory Services Section */}
              <br />
              <p className='text-l font-semibold'>
                2) Advisory and Portfolio Building Services
              </p>
              <p>
                At ATHEM Financial Services, our advisory and portfolio building services are designed to help you build a well-balanced, diversified equity portfolio that aligns with your financial goals and create tailored solutions that focus on both wealth creation and risk management.
                {isExpandedAdvisory && (
                  <>
                    <br />Customized Investment Strategies: We develop personalized portfolios based on your risk tolerance, and time horizon, ensuring a tailored approach to long-term wealth building.
                    <br />Ongoing Advisory and Management: We provide continuous portfolio management and advisory, ensuring that your investments are aligned with evolving market conditions and your financial goals.
                    <br />Risk Management Solutions: Protect your capital with advanced risk management techniques, including volatility assessments and diversification.
                  </>
                )}
                <br />
                <button onClick={() => setIsExpandedAdvisory(!isExpandedAdvisory)} className="text-blue font-semibold underline decoration-blue">
                  {isExpandedAdvisory ? 'See Less...' : 'See More...'}
                </button>
              </p>

              {/* Portfolio Restructuring Section */}
              <br />
              <p className='text-l font-semibold'>
                3) Portfolio Restructuring/Rebalancing
              </p>
              <p>
                Periodic portfolio restructuring or rebalancing is essential to maintaining a portfolio that reflects your financial goals...
                {isExpandedPortfolio && (
                  <>
                    <br />Strategic Portfolio Realignment: We evaluate your current portfolio and realign it to reflect your evolving financial goals, risk appetite, and market conditions, ensuring optimal performance.
                    <br />Risk Reduction Through Rebalancing: Rebalance your portfolio to reduce risk and volatility by adjusting your allocation to stocks and alternative assets.
                    <br />Sector and Asset Class Adjustments: As market conditions change, we adjust your exposure to various sectors and asset classes to capitalize on new opportunities and protect against downside risks.
                    <br />Manual Rebalancing: Regular reviews and adjustments to maintain an optimal risk-return profile through manual rebalancing.
                  </>
                )}
                <br />
                <button onClick={() => setIsExpandedPortfolio(!isExpandedPortfolio)} className="text-blue font-semibold underline decoration-blue">
                  {isExpandedPortfolio ? 'See Less...' : 'See More...'}
                </button>
              </p>

              {/* Paid Consultation Section */}
              <br />
              <p className='text-l font-semibold'>
                4) 1-on-1 Paid Consultation for Stock Portfolio Analysis
              </p>
              <p>
                At ATHEM Financial Services, we offer exclusive 1-on-1 paid consultations for detailed stock portfolio analysis. This service is ideal for clients looking for personalized, expert advice on optimizing their stock investments and making informed decisions to maximize returns in the long run.
                {isExpandedConsultation && (
                  <>
                    <br />Personalized Stock Portfolio Review: Get a thorough review of your current stock portfolio, including performance analysis, risk assessment, and sector diversification.
                    <br />Investment Strategy Optimization: Our experts will offer insights on how to optimize your stock investments, suggest potential opportunities, and provide actionable recommendations tailored to your financial goals.
                    <br />Risk Management Consultation: Identify and mitigate risks in your stock portfolio with expert advice on risk exposure, and current market trends.
                    <br />Sector and Market Trend Insights: Benefit from our analysis of specific market sectors and stocks, providing you with clarity on current trends, undervalued stocks, and growth opportunities.
                  </>
                )}
                <br />
                <button onClick={() => setIsExpandedConsultation(!isExpandedConsultation)} className="text-blue font-semibold underline decoration-blue">
                  {isExpandedConsultation ? 'See Less...' : 'See More...'}
                </button>
              </p>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
// App.js
import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import ScrollToTop from './components/ScrollToTop';
import Modal from './components/Modal';
import { About } from './pages/About';
import { ServicesPage } from './pages/ServicesPage';
import Favicon from "react-favicon";
import logo from "../src/images/logo3.png";

// Import Google Analytics functions
import { initGA, logPageView } from "./analytics";

// Component to track page views
function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname); // Log page view on route change
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useEffect(() => {
    initGA(); // Initialize Google Analytics when the app loads
  }, []);

  return (
    <Router>
      <RouteTracker />
      <Modal />
      <ScrollToTop>
        <Favicon url={logo} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<ServicesPage />} />
          {/* <Route path="/get-demo" element={<DemoProduct />} /> */}
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;

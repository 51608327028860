import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import AreaModal from './areaModal'; // Import the Modal component
import serviceData from './AreaOfServiceData'; // Import the service data

const Services = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({ title: '', content: '' });

    const openModal = (title, content, description) => {
        setModalData({ title, content, description });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div id="services" className="bg-gray-100 py-12">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue uppercase font-bold">
                        Area of Expertise
                    </h2>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue">
                        We are deeply committed to the growth and success of our clients.
                    </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                        {serviceData.map((service) => (
                            
                            <div
                                key={service.id}
                                className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group"
                            >
                                
                                <div className="m-2 text-left text-sm">
                                    <img
                                        alt={service.title}
                                        className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                        src={service.image}
                                    />
                                    
                                    <h2 className="font-semibold my-4 text-2xl text-left">{service.title}</h2>
                                    {/* <p className="text-md font-medium">{service.description}</p> */}
                                    {/* <br/> */}
                                    <button
                                        className="Read-more-button font-semibold lg:absolute lg:bottom-3 text-blue"
                                        onClick={() => openModal(service.title, service.modalContent,service.description)}
                                    >
                                        Read More
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            className="arrow ml-1 mt-1 transition-transform duration-300 group-hover:translate-x-2"
                                        />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {isModalOpen && (
                    <AreaModal
                        title={modalData.title}
                        content={modalData.content}
                        description={modalData.description}
                        onClose={closeModal}
                    />
                )}
            </section>
        </div>
    );
};

export default Services;

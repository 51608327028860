import React from 'react';

function ServicesBanner() {
  return (
    <div className="relative h-64 bg-greys hidden lg:block mt-20">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
        <h1 className="text-4xl font-bold mb-2 text-blue">Our Services</h1>
        <p className="text-xl text-blue">Delivering Excellence and Innovation</p>
      </div>
    </div>
  );
}

export default ServicesBanner;
// import React from 'react';
// import { Link } from 'react-router-dom';
// import NavBar from '../components/Navbar/NavBar';
// import heroImg from '../attachments/undraw_make_it_rain_re_w9pc.svg';

// const Hero = () => {
//     return (
//         <>
//             <div className="hero" id='hero'>                
//             <div>
//                     <NavBar />
//                 </div>
//                 <div className="overflow-hidden mx-4 mt-20 lg:mt-30 p-2 md:p-12 h-5/6" data-aos="zoom-in">
//                     <div className="flex flex-col lg:flex-row py-8 justify-between text-left lg:text-left">
//                         <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
//                             <h1 className="mb-5 md:text-4xl text-2xl font-bold text-blue">
//                             {/* We build digital solutions to help businesses scale */}
//                             Invest Smart, Invest Now
//                             </h1>
//                             <div className="text-lg font tracking-tight mb-3 text-gray-500">Welcome to ATHEM Financial Services, where expert market analysis meets personalized financial solutions. We specialize in providing deep insights into equities, currencies, and commodities on a global level empowering you to make informed investment decisions.
//                             Whether you're an individual investor or a business, our team is here to help you navigate the complexities of the financial markets with confidence and clarity. Let us guide your financial journey to success.
//                             </div>
//                             <div className="mb-4 hidden lg:block space-x-0 md:space-x-2 md:mb-8">
//                                 <Link to="/contact" className="text-whites bg-blue hover:bg-bluehover inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
//                                     Invest Now
//                                     <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//                                 </Link>
//                                 {/* <Link to="/contact" className="text-white bg-blue hover:text-bluehover inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
//                                     Get Started
//                                     <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//                                 </Link> */}
//                                 {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
//                                     Learn More
//                                     <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
//                                 </a> */}
//                             </div>
//                         </div>
//                         <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
//                             <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={heroImg} />
//                         </div>
//                         <div className="mb-4 space-x-0 md:space-x-2 text-center lg:hidden md:mb-8">
//                                 <Link to="/contact" className="text-whites bg-blue hover:bg-bluehover inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
//                                     Contact Us
//                                     <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//                                 </Link>
//                                 {/* <Link to="/contact" className="text-white bg-blue hover:text-bluehover inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
//                                     Get Started
//                                     <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//                                 </Link> */}
//                                 {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
//                                     Learn More
//                                     <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
//                                 </a> */}
//                             </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Hero;











'use client'

import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import NavBar from '../components/Navbar/NavBar'
import heroImg from '../attachments/undraw_make_it_rain_re_w9pc.svg'

export default function Hero() {
  return (
    <div className="hero" id="hero">
      <div>
        <NavBar />
      </div>
      <div
        className="overflow-hidden mx-4 mt-20 lg:mt-30 p-2 md:p-12 h-5/6"
        data-aos="zoom-in"
      >
        <div className="flex flex-col lg:flex-row py-8 justify-between text-left lg:text-left">
          <div
            className="lg:w-1/2 flex flex-col justify-center"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <motion.h1
              className="mb-5 md:text-5xl text-3xl font-bold text-blue"
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 4, ease: "easeOut" }}
            >
              Transforming Investments into Opportunities
            </motion.h1>
            <div className="mb-4 hidden lg:block space-x-0 md:space-x-2 md:mb-8">
                                    <Link to="/contact" className="text-whites bg-blue hover:bg-bluehover inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                  Invest Now
                                  <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                               </Link>
            </div>
          </div>
          <div
            className="flex lg:justify-end w-full lg:w-1/2"
            data-aos="fade-up"
            data-aos-delay="700"
          >
            <img
              alt="card img"
              className="rounded-t float-right duration-1000 w-full"
              src={heroImg}
            />
          </div>
          <div className="mb-4 space-x-0 md:space-x-2 text-center lg:hidden md:mb-8">
            <Link
              to="/contact"
              className="text-whites bg-blue hover:bg-bluehover inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
            >
              Invest Now
              <svg
                className="w-4 h-4 ml-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
// serviceData.js
import img1 from "../attachments/undraw_term_sheet_re_ju7s.svg"
import img2 from "../attachments/undraw_projections_re_ulc6.svg"
import img3 from "../attachments/undraw_visualization_re_1kag.svg"
import img4 from "../attachments/undraw_printing_invoices_-5-r4r.svg"
const serviceData = [
    {
        id: 1,
        title: "Stocks and IPOs",
        image: img1,
        description: "Investing in Stocks and IPOs can be a key driver of wealth creation. Our team at ATHEM Finserv provides comprehensive analysis and insights into stock market trends, helping you make informed decisions. Whether you're interested in established stocks or upcoming IPOs, we guide you through every step to maximize returns.",
        modalContent:
            [
                " Tailored Stock Investment Strategies: We provide personalized stock portfolio management designed to align with your financial goals and risk tolerance. Our approach focuses on growth, value, and dividend-yielding stocks, ensuring a balanced strategy.",
                "IPO Advisory Services: Navigate Initial Public Offerings (IPOs) with expert guidance on IPO research, investment timing, and allocation strategies, helping you capitalize on new opportunities in the public markets.",
                "Real-Time Market Analysis: Stay ahead of market trends with our cutting-edge tools for real-time stock market analysis, incorporating advanced technical based theories to optimize your trading decisions.",
                "Risk Management Solutions: We employ dynamic risk management techniques to protect your capital, using stop-loss orders, equity diversification across various sectors, and volatility assessments to minimize downside risks.",
                " Long-Term Wealth Creation: Our focus is on building wealth over time through well-researched stock picks and market timing strategies, keeping you informed of the latest stock trends and industry sectors.",
                "Stock Trading Platforms: Enjoy access to user-friendly trading platforms, offering seamless execution, and stock performance reports for informed decision-making."

            ]
    },
    {
        id: 2,
        title: "Futures and Options",
        image: img2,
        description: "Our Futures and Options (F&O) services offer advanced trading strategies to hedge risks and enhance returns. With expert market analysis and real-time insights, ATHEM Finserv empowers clients to make informed decisions in the derivatives market, facilitating flexible and tailored investment solutions.",
        modalContent:
            [
                "Comprehensive Derivatives Trading: We offer expertise in futures and options trading, providing tailored strategies that leverage market movements to maximize returns while minimizing risks.",
                "Options Hedging Strategies: Protect your portfolio with expertly crafted options hedging strategies, utilizing puts, calls, and spreads to hedge against market volatility and preserve capital.",
                "Leverage Opportunities: Enhance your earning potential by trading futures contracts with optimal leverage ratios, allowing you to capitalize on small price movements in key asset classes.",
                "Advanced Risk Control: Use futures and options to hedge existing positions in stocks, commodities, or currencies, minimizing potential losses through strategic derivatives exposure.",
                "Portfolio Diversification with Derivatives: Add depth to your investment portfolio through derivatives that offer diversification beyond traditional asset classes, reducing overall market exposure and risk."

            ]
    },
    {
        id: 3,
        title: "Currencies & Cryptocurrencies",
        image: img4,
        description: "Our Currency and Cryptocurrency Trading Services provide expert guidance for navigating the foreign exchange market (Forex) and emerging cryptocurrency markets like Bitcoin and Ethereum. We offer up-to-date market data and investment strategies to help you capitalize on currency fluctuations and cryptocurrency trends.",
        modalContent: [
            "Forex Trading Solutions: Gain access to our comprehensive forex trading services, allowing you to trade major, minor, and exotic currency pairs with real-time data and expert advisory.",
            "Currency Risk Management: Hedge against foreign exchange risks with our advanced currency hedging solutions, safeguarding your investments from volatility in global markets.",
            "Leverage Forex Opportunities: Utilize leverage to optimize forex trades, maximizing profits from small price movements in major currency pairs like USD, EUR, JPY, and GBP.",
            "Customized Currency Portfolios: Diversify your investment portfolio with curated currency baskets designed to balance risk and return, backed by deep research and technical analysis."

        ]
    },
    {
        id: 4,
        title: "Commodities",
        image: img3,
        description: "Commodity Trading at ATHEM Finserv helps investors diversify their portfolios through investments in key resources like gold, silver, oil, and agricultural products. Our expert team analyzes market trends to offer strategic entry and exit points for maximizing returns in this alternative asset class.",
        modalContent:
            [
                "Commodities Investment Advisory: Gain access to our expert advisory on commodities like gold, silver, oil, and agricultural products, helping you diversify and hedge your portfolio with tangible assets.",
                "Commodity Futures and Options: Trade commodity futures and options with confidence, using our advanced market insights and risk management tools to maximize profit potential while mitigating risks.",
                "Inflation Hedge Strategies: Protect your wealth from inflation with commodities that traditionally outperform in inflationary periods, including metals, energy, and agricultural products.",
                "Real-Time Market Insights: Stay ahead of commodity market trends with our real-time data analysis, providing up-to-date information on market trends and sentiment.",
                "Comprehensive Risk Management: Utilize our risk management frameworks to mitigate price volatility in commodities.",
                "ESG-Aligned Commodities: Explore sustainable commodity investments, including green energy and ethically sourced resources, aligning your portfolio with ESG (Environmental, Social, Governance) principles.",

            ]
    }
];

export default serviceData;

import React, { useState, useEffect } from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import ServicesSM from '../components/Services'; // Import the small screen version
import AnimatedCursor from "react-animated-cursor";
import WhatsAppIcon from './Whatsapp';
import Testimonials from '../components/Testimonials';
import { useDocTitle } from '../components/CustomHook';


// Custom hook to determine if the device is small
const useIsSmallScreen = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isSmallScreen;
};

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const Home = () => {
  const cursorColor = "0,94,231"; // RGB for blue
  const isSmallScreen = useIsSmallScreen();
  useDocTitle("HOME | ATHEM FINANCIAL SERVICES");

  return (
    <>
      {!isMobileDevice() && (
        <AnimatedCursor
          color={cursorColor}
          innerSize={10}
          outerSize={35}
          innerScale={1}
          outerScale={1.7}
          outerAlpha={0}
          outerStyle={{
            border: `1px solid rgba(${cursorColor}, 0.8)`,
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
            backgroundColor: `rgba(${cursorColor}, 0.2)`,
          }}
          innerStyle={{
            border: `1px solid rgba(${cursorColor}, 0.8)`,
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
            backgroundColor: `rgba(${cursorColor}, 0.2)`,
          }}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.link',
            'img'
          ]}
        />
      )}
      <Hero />
      <Intro />
      <WhatsAppIcon />
      {/* {isSmallScreen ? <ServicesSM /> : <Services />} */}
      <ServicesSM/>
      {/* <Portfolio /> */}
      {/* <Clients /> */}
      <Testimonials/>
      <Cta />
      <Footer />
    </>
  );
}

export default Home;
import React from 'react';

const AreaModal = ({ title, content, onClose, description }) => {
    return (
        <div className="fixed inset-0 flex items-start justify-center z-50 pt-20"> {/* Added pt-20 for padding below the navbar */}
            {/* Background overlay */}
            <div className="absolute inset-0 bg-slate-900/20 backdrop-blur-sm"></div>
            
            {/* Modal content */}
            <div className="bg-greys text-white rounded-lg p-6 w-4/5 h-fit max-w-4xl shadow-xl relative z-10 overflow-hidden">
                {/* Large background icon for visual effect */}
                <div className="absolute z-0 -top-24 -left-24 text-blue/10 rotate-12 text-[250px]">
                    {/* Placeholder for icon */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                </div>

                <div className="relative z-10">
                    <h2 className="text-3xl font-bold mb-4 text-left">{title}</h2>
                    <p className="text-left mb-4">{description}</p> 
                    {/* Render unordered list if content is an array, else render text */}
                    {Array.isArray(content) ? (
                        <ul className="list-disc list-inside mb-4 text-left ">
                            {content.map((item, index) => (
                                <li key={index} className="text-md font-medium pb-2">{item}</li>
                            ))}
                        </ul>
                    ) : (
                        ""/* Moved p tag inside the else block */
                    )}
                    
                    <div className="flex gap-2">
                        <button
                            onClick={onClose}
                            className="bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded-2xl"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AreaModal;

import React from 'react';

function AboutUsBanner() {
  return (
    <div className="relative h-64 bg-greys hidden lg:block mt-20">

      <div className="absolute inset-0">
      {/* <hr class="mt-10 w-full h-1.5 bg-whites"></hr> */}
      </div>
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
        <h1 className="text-4xl font-bold mb-2 text-blue">About Us</h1>
        <p className="text-xl text-blue">Learn more about our mission and values</p>
      </div>
    </div>
  );
}

export default AboutUsBanner;
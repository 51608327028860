import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <div className="flex flex-col lg:flex-row justify-between items-center gap-7 lg:gap-10">
            <HashLink className="px-4 font-extrabold text-xl lg:text-lg text-blue lg:text-whites hover:text-whites transition-transform duration-300 hover:scale-125" smooth to="/">
                Home
            </HashLink>
            <HashLink className="px-4 font-extrabold text-xl lg:text-lg text-blue lg:text-whites hover:text-whites transition-transform duration-300 hover:scale-125" smooth to="/about">
                About Us
            </HashLink>
            <HashLink className="px-4 font-extrabold text-xl lg:text-lg text-blue lg:text-whites hover:text-whites transition-transform duration-300 hover:scale-125" smooth to="/services">
                Our Services
            </HashLink>
            <HashLink className="px-4 font-extrabold text-xl lg:text-lg text-blue lg:text-whites hover:text-whites transition-transform duration-300 hover:scale-125" to="/contact">
                Contact Us
            </HashLink>
        </div>
    );
};

export default NavLinks;

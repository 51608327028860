import React from 'react';
import img from '../attachments/undraw_meet_the_team_re_4h082.svg';
import { Link, useLocation } from 'react-router-dom';

const Intro = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    return (
        <div className="mt-20 m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about'>
            <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                    <img alt="card img" className="rounded-t float-right" src={img} />
                </div>
                <div className="flex-col my-4 text-left lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                    <h3 className="text-3xl text-blue font-bold">Invest Wisely With ATHEM</h3>
                    <div>
                        <p className='my-3 text-lg text-gray-600 font'>
                            Our in-house research analysts offer one of a kind global LIVE market analysis, expert insights and personalized strategies to help you navigate the complexities of investing and achieve your long-term financial goals.
                        </p>
                    </div>
                    <div>
                        <p className='my-3 text-lg text-gray-600 font'>
                            Trust our expert team with your funds, and we will manage your risk and money to ensure your portfolio grows sustainably while protecting your capital. Start your journey to financial success with us and never look back. We continually seek market opportunities to maximize your profits.
                        </p>
                    </div>
                    <Link 
                        to={isHomePage ? "/about" : "/contact"} 
                        className="text-whites bg-blue hover:bg-bluehover inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
                    >
                        {isHomePage ? "Learn More" : "Contact Us"}
                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Intro;
import React, { useState } from 'react';

export default function Testimonials() {
  const [testimonialActive, setTestimonialActive] = useState(1);

  const testimonials = [
    {
      id: 1,
      text: "I've been using ATHEM Financial Services for equity and commodities analysis for the past few months, and I'm impressed with their in-depth market knowledge. Their personalized approach and attention to detail have helped me make more strategic investment choices.",
      name: "Nilesh Mody",
      title: "Diamond Merchant",
      initials: "NM"
    },
    {
      id: 2,
      text: "Working with ATHEM Financial Services has been a game changer. Their insights into equity and commodities markets have helped me make informed decisions and grow my portfolio. The team is professional and always available to provide timely advice. I highly recommend their services!",
      name: "Rajul Mehta",
      title: "Businessman and Investor",
      initials: "RM"
    },
    {
      id: 3,
      text: "Athem Financial Services has provided exceptional guidance in both equity and currency markets. Their analysis is always on point, and their strategies have helped me diversify and strengthen my investments. I’ve seen significant returns in just a few months!",
      name: "Parag Janve",
      title: "Founder of Detailing Cube and HNI Investor",
      initials: "PJ"
    },
    {
      id: 4,
      text: "I’ve been working with Athem for a few months now, and their commodities analysis has been a huge asset to my trading decisions. They’re knowledgeable, responsive, and provide clear, actionable insights. I feel confident with them on my side.",
      name: "Rajiv Mehta",
      title: "Commodity Trader",
      initials: "RM"
    },
    {
      id: 5,
      text: "The personalized service and expertise at Athem Financial Services are outstanding. Their detailed analysis of equity markets has given me a competitive edge, allowing me to make smarter investments. Their support over the last few months has been invaluable.",
      name: "Kshitij Janve",
      title: "Architect and Founder at Studio K",
      initials: "KJ"
    }
  ];

  return (
    <div className="mt-5 md:mt-3 mb-10 container mx-auto flex flex-col md:flex-row shadow-sm overflow-hidden">
      <div className="bg-blue relative w-full py-2 md:py-24 bg-indigo-700 md:w-1/2 flex flex-col item-center justify-center">
        <div className="bg-blue absolute top-0 left-0 z-10 grid-indigo w-15 h-16 md:w-40 md:h-40 md:ml-20 md:mt-24"></div>
        <div className="relative text-2xl md:text-5xl py-2 px-6 md:py-6 md:px-1 md:w-64 md:mx-auto text-indigo-100 font-bold leading-tight tracking-tight mb-0 z-20">
          <h2 className="text-whites hidden md:block">What Our</h2>
          <h2 className="text-whites hidden md:block">Customers</h2>
          <h2 className="text-whites hidden md:block">Are Saying!</h2>
          <h2 className="text-whites whitespace-nowrap text-xl block md:hidden">What Our Customers Are Saying!</h2>
        </div>
        <div className="absolute right-0 bottom-0 mr-4 mb-4 hidden md:block">
          <button
            aria-label="Previous testimonial"
            className="bg-whites rounded-l-2xl border-r bg-gray-100 text-gray-500 focus:outline-none hover:text-indigo-500 font-bold w-12 h-10"
            onClick={() => setTestimonialActive(testimonialActive === 1 ? testimonials.length : testimonialActive - 1)}
          >
            &#8592;
          </button>
          <button
            aria-label="Next testimonial"
            className="bg-whites rounded-r-full bg-gray-100 text-gray-500 focus:outline-none hover:text-indigo-500 font-bold w-12 h-10"
            onClick={() => setTestimonialActive(testimonialActive >= testimonials.length ? 1 : testimonialActive + 1)}
          >
            &#8594;
          </button>
        </div>
      </div>

      <div className="bg-gray-100 md:w-1/2">
        <div className="flex flex-col h-full relative">
          <div className="absolute top-0 left-0 mt-3 ml-4 md:mt-5 md:ml-12">
            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue opacity-30 fill-current w-12 h-12 md:w-16 md:h-16" viewBox="0 0 24 24">
              <path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L9.758 4.03c0 0-.218.052-.597.144C8.97 4.222 8.737 4.278 8.472 4.345c-.271.05-.56.187-.882.312C7.272 4.799 6.904 4.895 6.562 5.123c-.344.218-.741.4-1.091.692C5.132 6.116 4.723 6.377 4.421 6.76c-.33.358-.656.734-.909 1.162C3.219 8.33 3.02 8.778 2.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C2.535 17.474 4.338 19 6.5 19c2.485 0 4.5-2.015 4.5-4.5S8.985 10 6.5 10zM17.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L20.758 4.03c0 0-.218.052-.597.144-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162C14.219 8.33 14.02 8.778 13.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C13.535 17.474 15.338 19 17.5 19c2.485 0 4.5-2.015 4.5-4.5S19.985 10 17.5 10z"/>
            </svg>
          </div>

          <div className="h-full relative z-10" aria-live="polite">
            {testimonials.map((testimonial) => (
              testimonialActive === testimonial.id && (
                <p key={testimonial.id} className="text-gray-600 serif font-normal italic px-6 py-6 md:px-16 md:py-10 text-xl md:text-2xl">
                  {testimonial.text}
                </p>
              )
            ))}
          </div>

          <div className="flex my-4 justify-center items-center flex-wrap">
            {testimonials.map((testimonial) => (
              <button
                key={testimonial.id}
                onClick={() => setTestimonialActive(testimonial.id)}
                className={`bg-blue text-whites text-center font-bold shadow-xs focus:outline-none focus:shadow-outline inline-block rounded-full mx-2 my-2 ${
                  testimonialActive !== testimonial.id ? 'h-10 w-10 md:h-12 md:w-12 opacity-25 bg-indigo-300 text-gray-600' : 'h-12 w-12 md:h-16 md:w-16 opacity-100 bg-blue text-white'
                }`}
                aria-label={`Show testimonial ${testimonial.name}`}
              >
                {testimonial.initials}
              </button>
            ))}
          </div>

          <div className="flex justify-center px-6 pt-2 pb-6 md:py-6">
            {testimonials.map((testimonial) => (
              testimonialActive === testimonial.id && (
                <div key={testimonial.id} className="text-center">
                  <h3 className="text-sm md:text-base font-bold text-gray-700 leading-tight">{testimonial.name}</h3>
                  <small className="text-gray-500 text-xs md:text-sm truncate">{testimonial.title}</small>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
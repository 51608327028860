import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import { CgHello } from "react-icons/cg";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation

const Modal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Reset the modal state on each page load
    sessionStorage.setItem('hasShownModal', 'false');

    // Check if the modal has been shown in this session
    const hasShownModal = sessionStorage.getItem('hasShownModal');
    if (hasShownModal === 'false' && location.pathname !== '/contact') {
      setIsOpen(true); // Open modal on page load if not on /contact
      sessionStorage.setItem('hasShownModal', 'true');
    }
  }, []); // Add location.pathname as a dependency

  return (
    <div className="">
      <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

const SpringModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
        >
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className="bg-greys bg-gradient-to-br from-violet-600 to-indigo-600 text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
          >
            <CgHello className="text-blue/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
            <div className="relative z-10">
              <div className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-indigo-600 grid place-items-center mx-auto">
                <CgHello className="text-blue" />
              </div>
              <h3 className="text-blue text-3xl font-bold text-center mb-2">
                Need Stock Advice?
              </h3>
              <p className="text-center mb-6">
              Not sure if a stock is the right choice? Click "Yes" and get quick advice from our experts!

              </p>
              <div className="flex gap-2">
                <button
                  onClick={() => setIsOpen(false)}
                  className="bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded-2xl"
                >
                  Go back
                </button>
                <button
                  onClick={() => {
                    setIsOpen(false);
                    navigate('/contact'); // Navigate to /contact
                  }}
                  className="bg-transperent hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded-2xl"
                >
                  Yes
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;